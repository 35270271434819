.file-directory-container {
    width: 546px;
    height: calc(100% - 60px);
}

.file-directory-content {
    height: 100%;
    border: 1px solid #e50b54;
    border-radius: 4px;
}

.file-directory-header {
    padding: 8px 8px;
    border-bottom: 1px solid #e50b54;
}

.file-directory-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
}

.files-view-mode {
    border-radius: 4px !important;
}

.files-view-mode-selected {
    background-color: #f9f9f9 !important;
}

.file-directory-path {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 4px 0;
}

.path-text {
    font-size: 14px !important;
}

.path-text:hover {
    text-decoration: underline;
    cursor: pointer;
}

.file-directory-files {
    display: flex;
    overflow: auto;
    height: 400px;
    justify-content: flex-start;
    align-content: flex-start;
}

.list-view {
    display: flex;
    flex-direction: column;
    margin: 4px;
}

.squares-view {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 4px;
}

.file-directory-actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

.list-folder {
    padding: 4px 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.squares-folder {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80px;
    width: 80px;
}

.list-folder > img {
    height: 20px;
    width: 20px;
    margin-right: 2px;
}

.squares-file, .squares-folder {
    text-align: center;
    text-overflow: ellipsis;
}


.squares-folder img {
    height: 40px;
    width: 40px;
}

.folder-element:hover {
    background-color: #f9f9f9;
    cursor: default;
}

.file-element {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
}

.list-file {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px;
    border-radius: 4px;
}

.list-file > img {
    height: 20px;
    width: 20px;
    margin-right: 2px;
}

.squares-file img {
    height: 40px;
    width: 40px;
}

.squares-file {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80px;
    width: 80px;
}

.file-element:hover {
    cursor: pointer;
    background-color: #f9f9f9;
}

.file-element-selected {
    background-color: #f9f9f9;
}

.squares-file, .squares-folder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100px;
    text-align: center;
    overflow: hidden;
}

.list-file > .inter, .list-folder > .inter {
    font-size: 14px;
}

.squares-file > .inter, .squares-folder > .inter {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 100%;
    max-width: 90px;
}

